import { Fab, Typography } from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from "@material-ui/styles"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

/**
 * Renders the IndexPage component.
 * @returns {!React.Component}
 */
export default function IndexPage() {
  
  return ( 
    <Layout>
      <SEO title={"Home"} />

      <Typography variant="h5" component="h2" gutterBottom>
        Welcome to Wine Stats!
      </Typography>

      <Typography variant="body1" gutterBottom>
        Wine Stats enhances your wine experience by guiding you through tastings,
        storing the wines you've tasted, and will eventually help recommend different
        types of wine to try.
      </Typography>

      <Typography variant="body1">
        Understanding of wine comes through deliberate practice and mindful tasting.
        Enjoy!
      </Typography>

      <Fab aria-label="Add a wine tasting" color="primary">
        <AddIcon></AddIcon>
      </Fab>
    </Layout>
  );
}
